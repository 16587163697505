import React, { useState } from 'react';

import { Button, IconButton, Menu, MenuItem, TextField } from '@mui/material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { Chip } from 'primereact/chip';

import { useDispatch } from 'react-redux';

import { ContextMenu, ContextMenuItem, ContextMenuTrigger } from 'rctx-contextmenu';
import { generateSkillLevels, updateSkill, deleteSkill } from '../../../../redux/Skill';

import styles from './Skill.module.scss';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import { useNavigate } from 'react-router-dom';
import Levels from './Levels';
import { Skill as SkillI } from '@/redux/Skill/types';
import { AppDispatch } from '@/redux/store';

interface Props {
  skill: SkillI;
  dragHandleProps: any;
}

const Skill = ({ skill, dragHandleProps }: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const [isEditing, setIsEditing] = useState(false);
  const [newSkillName, setNewSkillName] = useState(skill.name);
  const [tags, setTags] = useState(skill.tags || []);
  const [newTagInput, setNewTagInput] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();

  const handleDeleteSkill = () => {
    dispatch(deleteSkill(skill.id));
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleSaveSkill();
    }
  };

  const handleEditSkill = () => {
    setIsEditing(true);
  };

  const handleSaveSkill = () => {
    dispatch(updateSkill({ ...skill, name: newSkillName }));
    setIsEditing(false);
  };

  const completedLevels = skill.levels?.reduce((acum, level) => (level?.completed ? acum + 1 : acum), -1);

  const handleGenerateLevels = () => {
    dispatch(generateSkillLevels(skill));
  };

  const handleOpenTree = () => {
    navigate(`/tree/${skill.id}`);
  };

  const handleDeleteTag = (tagToDelete: any) => {
    setTags(tags.filter((tag) => tag.title !== tagToDelete.title));
  };

  const handleNewTagKeyDown = (event: any) => {
    if (event.key === 'Enter' && newTagInput.trim() !== '' && !tags.some((tag) => tag.title === newTagInput.trim())) {
      const updatedTags = [...tags, { title: newTagInput.trim() }];
      setTags(updatedTags);
      setNewTagInput('');
    }
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={styles.skill}>
      <div className={styles.skillHeader}>
        <div {...dragHandleProps} className={styles.dragHandle}>
          <DragIndicatorIcon />
        </div>
        {isEditing ? (
          <TextField onKeyDown={handleKeyDown} value={newSkillName} onChange={(e) => setNewSkillName(e.target.value)} onBlur={handleSaveSkill} autoFocus />
        ) : (
          <>
            <div className={styles.skillName}>{skill.name}</div>
            <div className={styles.tagsContainer}>
              {tags.map((tag, index) => (
                <Chip key={index} label={tag.title} removable onRemove={() => handleDeleteTag(tag)} className={styles.tagChip} />
              ))}
              <div className={styles.addTag}>
                <input
                  type="text"
                  value={newTagInput}
                  onChange={(e) => setNewTagInput(e.target.value)}
                  onKeyDown={handleNewTagKeyDown}
                  className={styles.addTagInput}
                  placeholder="Добавить тег"
                />
              </div>
            </div>
          </>
        )}
        {completedLevels >= 0 && <div className={styles.completedLevel}>Уровень: {completedLevels + 1}</div>}
        {/* Button for opening the options menu */}
        <IconButton onClick={handleClick} size="small" className={styles.optionsButton}>
          <MoreVertIcon />
        </IconButton>
      </div>

      {/* Menu with options */}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleOpenTree}>
          <AccountTreeIcon fontSize="small" style={{ marginRight: '8px' }} />
          Открыть в режиме дерева
        </MenuItem>
        <MenuItem onClick={handleGenerateLevels}>
          <AutoFixHighIcon fontSize="small" style={{ marginRight: '8px' }} />
          Генерировать уровни
        </MenuItem>
        <MenuItem onClick={handleEditSkill}>
          <EditIcon fontSize="small" style={{ marginRight: '8px' }} />
          Редактировать навык
        </MenuItem>
        <MenuItem onClick={handleDeleteSkill}>
          <DeleteIcon fontSize="small" style={{ marginRight: '8px' }} />
          Удалить навык
        </MenuItem>
      </Menu>

      <Levels skill={skill} />
    </div>
  );
};

export default Skill;
