import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SplitButton from '@/components/DropDownButton';
import { Link } from 'react-router-dom';
import styles from './MenuButton.module.scss';

export default function BasicMenu({ setBuilderEnabled }: any) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickLogOut = () => {
    localStorage.removeItem('GAME_USER_ID');
    window.location.href = '/';
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Меню
      </Button>

      <Menu
        className={styles.menu}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem className={styles.menuItem}>
          <Link className={styles.link} to={'/profile'}>
            Профиль
          </Link>
        </MenuItem>
        {/* <MenuItem>
          <Link className={styles.link} to={'/characteristics'}>
            Характеристики
          </Link>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setBuilderEnabled(true);
            handleClose();
          }}>
          Построить График
        </MenuItem> */}
        <MenuItem className={styles.menuItem}>
          Экспортировать <SplitButton />
        </MenuItem>
        <MenuItem className={styles.menuItem} onClick={onClickLogOut}>
          Выйти
        </MenuItem>
      </Menu>
    </div>
  );
}
